import React, { Component } from "react";
import home from './home-solid.svg';
import outside from './pagelines-brands.svg';
import gasimage from './flame.svg';
import powerimage from './electricity-home.svg';
import temp from './thermometer-three-quarters-solid.svg';
import hum from './water-solid.svg';
import pre from './pressure.svg';
import { Line } from 'react-chartjs-2';
import './App.css';


class Forecast extends Component {
    constructor(props){
        super(props);
        this.state = {
            forecast : []
        };
    }

    getData = () => {
        Promise.all([
            fetch('http://dyn.ionet.de:9000/forecast')
        ])
        .then(([res1]) => Promise.all([res1.json()]))
        .then(([data1]) => this.setState({forecast: data1}));
      }

    componentDidMount(){
        this.getData();
        setInterval(this.getData, 60*60*1000);
    }  

    render() {
        const dayname = ["SO","MO","DI","MI","DO","FR","SA"];        
        return (
            <div className="table"> 
                <table>
                    <tr>{this.state.forecast.map(function(day, index){return <td>{ dayname[day.day] }</td>})}<td></td></tr> 
                    <tr>         
                    {this.state.forecast.map(function(day, index){
                        return <td>{ Math.round(day.temp_max-273.15) } bis { Math.round(day.temp_min-273.15) } &#8451;</td>
                    })}
                  </tr>
                  <tr>         
                    {this.state.forecast.map(function(day, index){
                        return <td>{day.clouds}% {day.sun}h</td>
                    })}
                  </tr>                  
                  <tr>         
                    {this.state.forecast.map(function(day, index){
                        return <td>{day.wind_bt} Bft ({day.wind_dsc})</td>
                    })}
                  </tr>
                  <tr>         
                    {this.state.forecast.map(function(day, index){
                        let p = Math.round(day.precipitation);                      
                        return <td>{ p>0 ? p+' mm' : '' } {day.precipitation_type}</td>
                  })}
                  </tr>
                </table>
            </div>
);
    }
}

class Weather extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            temperatures: [],
            humidities: [],
            pressures: [],
            day: {},
            power: {},
            now : new Date()
        };
    }

    getData = () => {
        Promise.all([
            fetch('http://dyn.ionet.de:9000/temperature'),
            fetch('http://dyn.ionet.de:9000/humidity'),
            fetch('http://dyn.ionet.de:9000/gas'),
            fetch('http://dyn.ionet.de:9000/power'),
            fetch('http://dyn.ionet.de:9000/pressure')
        ])
        .then(([res1, res2, res3, res4, res5]) => Promise.all([res1.json(), res2.json(), res3.json(), res4.json(), res5.json() ]))
        .then(([data1, data2, data3, data4, data5]) => this.setState({temperatures: data1, humidities: data2, day: data3, power: data4, pressures: data5, now: new Date()}));
      }

    componentDidMount(){
        this.getData();
        setInterval(this.getData, 50*1000);
    }

    getTemperature(station) {
        for (var i=0; i<this.state.temperatures.length; i++) {
            if (station.localeCompare(this.state.temperatures[i].sender)==0) {
                return this.state.temperatures[i];
            }
        }
    }

    getHumidity(station) {
        for (var i=0; i<this.state.humidities.length; i++) {
            if (station.localeCompare(this.state.humidities[i].sender)==0) {
                return this.state.humidities[i];
            }
        }
    }

    getPressure(station) {
      for (var i=0; i<this.state.pressures.length; i++) {
          if (station.localeCompare(this.state.pressures[i].sender)==0) {
              return this.state.pressures[i];
          }
      }
    }

    render() {        
        var temp1 = "N/A";
        var hum1 = "N/A";
        var temp2 = "N/A";        
        var pressure = "N/A"; 
        var temp4 = "N/A";
        var temp5 = "N/A";
        var temp6 = "-";
        var hum2 = "N/A";
        var hum3 = "N/A";
        var timediff = -1;
        var timediff2 = -1;
        var gas = (Math.round(this.state.day.consumption*100)/100).toFixed(2); 
        const power_t = new Date(this.state.power.timestamp);
        var power = this.state.power.consumption / 1000;
        
        var power_timestamp = this.state.power.timestamp ? power_t.getHours()+":"+(power_t.getMinutes()<10 ? "0"+power_t.getMinutes() : power_t.getMinutes()) : "N/A";
        const gas_t = new Date(this.state.day.timestamp);
        var gas_timestamp = this.state.day.timestamp ? gas_t.getHours()+":"+(gas_t.getMinutes()<10 ? "0"+gas_t.getMinutes() : gas_t.getMinutes()) : "N/A";
        const mytime = this.state.now.getHours() + ":" + (this.state.now.getMinutes() < 10 ? "0"+this.state.now.getMinutes() : this.state.now.getMinutes());
        const gardenTemp = this.getTemperature("GARDEN1");
        const gardenHum = this.getHumidity("GARDEN1");
        const gardenPressure = this.getPressure("GARDEN1");
        const homeTemp = this.getTemperature("HOME1");
        const home2Temp = this.getTemperature("HOME2");
        const homeHum = this.getHumidity("HOME1");
        const home2Hum = this.getHumidity("HOME2");
        if (gardenTemp) {
            temp2 = gardenTemp.temperature;
            temp4 = gardenTemp.temp_avg;
            timediff2 = Math.round((this.state.now.getTime() - gardenTemp.seen) / (1000*60));
        };     
        if (gardenHum) {
            hum2 = Math.round(gardenHum.humidity);
        };  
        if (gardenPressure) {
            pressure = (gardenPressure.pressure / 100).toFixed(1);
        };
        if (homeTemp) {
            temp1 = homeTemp.temperature;
            temp5 = homeTemp.temp_avg;
            timediff = Math.round((this.state.now.getTime() - homeTemp.seen) / (1000 * 60));
        };
        if (homeHum) {
            hum1 = Math.round(homeHum.humidity);
        };
        if (home2Hum) {
            hum3 = Math.round(home2Hum.humidity);
        };        
        if (home2Temp) {
            temp6 = home2Temp.temperature;
        };
        
        return (
            <div>{mytime}
                {(timediff > 4 || timediff2 > 4) &&
                    <div>G:{timediff2} H:{timediff} min alt!</div>
                }
                <div className="blocks">
                    <div className="block">
                        <div className="blockheader"><img src={home} alt="home" height="30px" /></div>
                        <div className="icon1"><img src={temp} alt="temp" height="30px" /></div>
                        <div className="text1">{temp1}|{temp6} &#8451;</div>                                                
                        <div className="icon2"><img src={hum} alt="hum" height="30px" /></div>
                        <div className="text3">{hum1}|{hum3} %</div>
                    </div>
                    <div className="block">
                        <div className="blockheader"><img src={outside} alt="outside" height="30px" /></div>
                        <div className="icon1"><img src={temp} alt="temp" height="30px" /></div>
                        <div className="text1">{temp2} &#8451;</div>                                                                  
                        <div className="icon2"><img src={hum} alt="hum" height="30px" /></div>                        
                        <div className="text3">{hum2} %</div>
                        <div className="icon3"><img src={pre} alt="hum" height="30px" /></div>                        
                        <div className="text4">{pressure} hPa</div>
                    </div>
                    <div className="block">
                        <div className="blockheader"><img src={gasimage} alt="gas" height="30px" /></div>
                        <div className="icon1"></div>
                        <div className="text1">{gas} m&#179;</div>                        
                        <div className="text2"></div>                        
                        <div className="icon2"></div>
                        <div className="text2">{gas_timestamp}</div>
                    </div>
                    <div className="block">
                        <div className="blockheader"><img src={powerimage} alt="power" height="30px" /></div>
                        <div className="icon1"></div>
                        <div className="text1">{power} kW</div>                                                
                        <div className="text2"></div>                        
                        <div className="icon2"></div>
                        <div className="text2">{power_timestamp}</div>
                    </div>
                </div>
            </div>
        );
    }
}

const options = {
    responsive: true,
    tooltips: {
      mode: 'label'
    },
    elements: {
      line: {
        fill: false
      }
    }
  };

const data = {
    labels: [],
    datasets: [
      {
        label: 'Home1',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: []
      },
      {
        label: 'Home2',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(45,162,162,0.4)',
        borderColor: 'rgba(45,162,162,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(45,162,162,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(45,162,162,1)',
        pointHoverBorderColor: 'rgba(190,190,190,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: []
      },      
      {
        label: 'Outside',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,92,92,0.4)',
        borderColor: 'rgba(155,92,92,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(5,92,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(5,92,192,1)',
        pointHoverBorderColor: 'rgba(22,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: []
      }
    ]
  };

  const pressuredata = {
    labels: [],
    datasets: [
      {
        label: 'Pressue (Pa)',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: []
      }
    ]
  };

  const powerdata = {
    labels: [],
    datasets: [
      {
        label: 'Power consumption today',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: []
      }
    ]
  };

  const powerydata = {
    labels: [],
    datasets: [
      {
        label: 'Power consumption per day',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: []
      },      
      {
        label: 'average',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,92,92,0.4)',
        borderColor: 'rgba(155,92,92,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(5,92,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(5,92,192,1)',
        pointHoverBorderColor: 'rgba(22,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: []
      }
    ]
  };

  const gasdata = {
    labels: [],
    datasets: [
      {
        label: 'Gas consumption today',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: []
      }
    ]
  };

  const gasydata = {
    labels: [],
    datasets: [
      {
        label: 'Gas consumption per day',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: []
      },      
      {
        label: 'average',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,92,92,0.4)',
        borderColor: 'rgba(155,92,92,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(5,92,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(5,92,192,1)',
        pointHoverBorderColor: 'rgba(22,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: []
      }
    ]
  };

  class App extends Component {

    constructor(props){
        super(props);
        this.state = {
            history : [],            
            pressurehistory : [],            
            gasday : [],
            gasyear : [],
            powerday: [],
            poweryear: []
        };
    }

    getData = () => {
        Promise.all([
            fetch('http://dyn.ionet.de:9000/temperature/history'),
            fetch('http://dyn.ionet.de:9000/pressure/history'),
            fetch('http://dyn.ionet.de:9000/gas/day'),
            fetch('http://dyn.ionet.de:9000/gas/year'),
            fetch('http://dyn.ionet.de:9000/power/day'),
            fetch('http://dyn.ionet.de:9000/power/year')
        ])
        .then(([res1,res6,res2,res3,res4,res5]) => Promise.all([res1.json(),res6.json(),res2.json(),res3.json(),res4.json(),res5.json()]))
        .then(([data1,data6,data2,data3,data4,data5]) => this.setState({history: data1, pressurehistory: data6, gasday: data2, gasyear: data3, powerday: data4, poweryear: data5}));
      }

      componentWillMount() {
        this.getData();    
      }
      
    componentDidMount(){    
        setInterval(this.getData, 10*60*1000);
    } 

    componentWillUpdate() {
        console.log("UPDATE!");        
    }

    componentWillUnmount() {
        console.log("UNMOUNT!");
    }

    render() {
        const axisStyle = {
            ticks: {
              fontSize: '14px',
              color: '#333'
            },
            title: {
              fontSize: '16px',
              color: '#333'
            }
          };
        var data2 = data;
        if (this.state.history.length>0) {
            data2.labels = [];
            data2.datasets[0].data = [];
            data2.datasets[1].data = [];
            data2.datasets[2].data = [];

            let dateRef = 0;
            let home1Temp = null;
            let home2Temp = null;
            let garden1Temp = null;
            for (var i=0; i<this.state.history.length; i++) {
                if (this.state.history[i].sender.localeCompare("GARDEN1") == 0) {
                    garden1Temp = this.state.history[i].temperature;
                } else if (this.state.history[i].sender.localeCompare("HOME1") == 0) {
                    home1Temp = this.state.history[i].temperature;
                } else if (this.state.history[i].sender.localeCompare("HOME2") == 0) {
                    home2Temp = this.state.history[i].temperature;
                }
                if (this.state.history[i].datum > (dateRef + 10*60*1000)) {
                    dateRef = this.state.history[i].datum;
                    let d = (new Date(dateRef)).getHours();
                    let m = (new Date(dateRef)).getMinutes();
                    data2.labels.push(d + ":" + (m < 10 ? "0" + m : m));
                    data2.datasets[0].data.push(home1Temp);
                    data2.datasets[1].data.push(home2Temp);
                    data2.datasets[2].data.push(garden1Temp);
                }
            }
        }
        var data7 = pressuredata;
        if (this.state.pressurehistory.length>0) {
            data7.labels = [];
            data7.datasets[0].data = [];

            let dateRef = 0;
            let garden1Pres = null;
            for (var i=0; i<this.state.pressurehistory.length; i++) {
                if (this.state.pressurehistory[i].sender.localeCompare("GARDEN1") == 0) {
                    garden1Pres = (this.state.pressurehistory[i].pressure / 100).toFixed(1);
                } 
                if (this.state.pressurehistory[i].datum > (dateRef + 10*60*1000)) {
                    dateRef = this.state.pressurehistory[i].datum;
                    let d = (new Date(dateRef)).getHours();
                    let m = (new Date(dateRef)).getMinutes();
                    data7.labels.push(d + ":" + (m < 10 ? "0" + m : m));
                    data7.datasets[0].data.push(garden1Pres);
                }
            }
        }
        var data3 = gasdata;
        if (this.state.gasday.length>0) {
            data3.labels = [];
            data3.datasets[0].data = [];
                        
            for (var i = 0; i < this.state.gasday.length; i++) {
                var d = (new Date(this.state.gasday[i].timestamp)).getHours();
                var m = (new Date(this.state.gasday[i].timestamp)).getMinutes();
                data3.labels.push(d + ":" + (m < 10 ? "0" + m : m));                
                data3.datasets[0].data.push((Math.round(this.state.gasday[i].consumption * 100) / 100).toFixed(2));                
            }
        }
        var data4 = gasydata;
        if (this.state.gasyear.length>0) {
            data4.labels = [];
            data4.datasets[0].data = [];            
            data4.datasets[1].data = [];

            let average = 0;
            for (var i = 0; i < this.state.gasyear.length; i++) {                
                data4.labels.push(this.state.gasyear[i].month + "/" + this.state.gasyear[i].day);
                data4.datasets[0].data.push((Math.round(this.state.gasyear[i].consumption * 100) / 100).toFixed(2));
                average += this.state.gasyear[i].consumption;
                data4.datasets[1].data.push((average*100/100/(i+1)).toFixed(2));
            }
        }
        var data5 = powerdata;
        if (this.state.powerday.length>0) {
            data5.labels = [];
            data5.datasets[0].data = [];
                        
            for (var i = 0; i < this.state.powerday.length; i++) {
                var d = (new Date(this.state.powerday[i].timestamp)).getHours();
                var m = (new Date(this.state.powerday[i].timestamp)).getMinutes();
                data5.labels.push(d + ":" + (m < 10 ? "0" + m : m));                
                data5.datasets[0].data.push(this.state.powerday[i].consumption / 1000);
            }
        }
        var data6 = powerydata;
        if (this.state.poweryear.length>0) {
            data6.labels = [];
            data6.datasets[0].data = [];            
            data6.datasets[1].data = [];

            let average = 0;
            for (var i = 0; i < this.state.poweryear.length; i++) {                
                data6.labels.push(this.state.poweryear[i].month + "/" + this.state.poweryear[i].day);
                data6.datasets[0].data.push(Math.round(this.state.poweryear[i].consumption / 1000));
                average += this.state.poweryear[i].consumption;
                data6.datasets[1].data.push((Math.round(average/(i+1))/1000).toFixed(1));
            }
        }
        return (
            <div className="App">
                <header className="App-header">
                    <Weather />
                    <Forecast /> 
                </header>
                <div className="App-chart">                       
                    <Line data={data} options={options}/>                
                    <Line data={data7} options={options}/>                
                    <Line data={powerdata} options={options}/>
                    <Line data={powerydata} options={options}/>
                    <Line data={gasdata} options={options}/>
                    <Line data={gasydata} options={options}/>
                </div>                         
            </div>
        );
    }
}

export default App;
